import React from 'react'
import styles from './footer.module.scss'
import { Link } from 'react-router-dom'
import { ENVIRONMENT } from '../../environment'
import { ReactComponent as Design } from '../../assets/svg/footerDesign.svg'
import { ReactComponent as LinkedIn } from '../../assets/svg/LinkedIn.svg'
import { ReactComponent as Facebook } from '../../assets/svg/Facebook.svg'
import { ReactComponent as XIcon } from '../../assets/svg/twitter.svg'
import { ReactComponent as Instagram } from '../../assets/svg/instagram.svg'
import { ReactComponent as Youtube } from '../../assets/svg/youtube.svg'
import { ReactComponent as ZunuLogoColored } from '../../assets/svg/zunu-logo-white.svg'
import ZirohLogo from '../../assets/png/zirohLogo.png'

function Footer() {
  return (
    <div className={styles.footer}>
      <div className={styles.footerHeader}>
        <div className={styles.headerLogoContainer}>
          <img src={ZirohLogo} alt="Ziroh Labs" className={styles.zirohLogo} />

          <ZunuLogoColored className={styles.zunuLogo} />

        </div>
      </div>
      <div className={styles.linksContainer}>
        <Link reloadDocument to="https://zunuprivacy.com/privacy-policy" target="_blank">Privacy Policy</Link>

        <Link reloadDocument to="https://zunuprivacy.com/terms-of-service" target="_blank">Terms of Service</Link>

        {/* <Link reloadDocument to="https://gozunu.com/pricing" target="_blank">Plans & Pricing</Link> */}

        <Link reloadDocument to="https://academic.ziroh.com" target="_blank">Academic Alliance</Link>

        <Link reloadDocument to="#">Careers</Link>

        <Link reloadDocument to="https://zunuprivacy.com/support" target="_blank">Support</Link>

        <Link reloadDocument to="https://zunuprivacy.com/contact-us" target="_blank">Contact Us</Link>

      </div>
      <div className={styles.privacyMediaContainer}>
        <div className={styles.privacyLogoContainer}>
          {/* {theme == "light" ? (
          <img src={PrivacyMattersLight} alt="" loading="lazy" />
         ) : (
          <img src={PrivacyMattersDark} alt="" loading="lazy" />
         )} */}
          Keep your <span>private</span> life <span>private.</span>
        </div>

        <div className={styles.mediaIconContainer}>
          <Link reloadDocument to={ENVIRONMENT.socialMediaUrls.linkedin} target="_blank"><LinkedIn className={styles.mediaIcons} /></Link>
          <Link reloadDocument to={ENVIRONMENT.socialMediaUrls.facebook} target="_blank"><Facebook className={styles.mediaIcons} /></Link>
          <Link reloadDocument to={ENVIRONMENT.socialMediaUrls.twitter} target="_blank"><XIcon className={styles.mediaIcons} /></Link>
          <Link reloadDocument to={ENVIRONMENT.socialMediaUrls.instagram} target="_blank"><Instagram className={styles.mediaIcons} /></Link>
          <Link reloadDocument to={ENVIRONMENT.socialMediaUrls.youtube} target="_blank"> <Youtube className={styles.mediaIcons} /></Link>
        </div>

      </div>


      <p className={styles.copyright}>© 2025 Ziroh Labs Inc. All rights reserved.</p>

      <Design className={styles.design} />

    </div>
  )
}

export default Footer